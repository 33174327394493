export type Estado = {
  id?: number;
  idUF: string;
  nmUF: string;
  cdIbge: number;
  flNfe: EstadoNFE;
};

export enum EstadoNFE {
  EMITENTE = 'S',
  NAO_EMITENTE = 'N',
}
