export type TipoConta = {
  id: number;
  nmTipo: string;
  dsTipo?: string;
  nrContaCredito?: number | string;
  nrContaDebito?: number | string;
  flSituacao: string;
};

export enum TipoContaSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
