import { EmitCRT } from './NFe400/Enum/EmitCRT';

export type Empresa = {
  id: number;

  /** Código do cadastro do usuário realizado via site. */
  idCadastro: number;

  /** Código da cidade */
  idCidade: number;

  /** Informa se o registro esta ativo/inativo */
  flSituacao: EmpresaSituacao;

  /** Código do regime tributário ( 1 - Simples Nacional, 2 - Simples Nacional - excesso de sublimite da receita bruta, 3 - Regime Normal ) */
  flCRT: EmitCRT;

  /**
   * Tipo de empresa (
   * F = Pessoa Física,
   * P = Pessoa Jurídica )
   */
  flTipo: EmpresaTipo;

  /** Razão Social */
  nmRazaoSocial: string;

  /** Nome Fantasia */
  nmFantasia: string;

  /** Número do CNPJ ou CPF (De acordo com flTipo) */
  nrCNPJ: string;

  /** Inscrição estadual */
  nrIE?: string | undefined | null;

  /** Inscrição municipal */
  nrIM?: string | undefined | null;

  /** Código postal */
  nrCEP: string;

  /** Logradouro */
  nmLogradouro: string;

  /** Endereço */
  nrEndereco: string;

  /** Bairro */
  nmBairro: string;

  /** E-Mail */
  dsEmail?: any;

  /** Nome do responsável */
  nmResponsavel: string;

  /**
   * Complemento
   * VARCHAR(100)
   */
  dsComplemento: string;

  /** Número do telefone */
  nrTelefone: string;

  /** Cidade relacionada (de acordo com idCidade) */
  Cidade?: any;
};

export enum EmpresaTipo {
  PESSOA_FISICA = 'F',
  PESSOA_JURIDICA = 'P',
}

export enum EmpresaSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
