import { TUFIBGE } from '../TUFIBGE';

export type RefNFP = {
  cUF: TUFIBGE;
  AAMM: string;
  Documento: string | 'CPF' | 'CNPJ';
  TipoDocumento: RefNFPTipoDocumento;
  IE: string;
  serie: string;
  nNF: string;
};

export enum RefNFPTipoDocumento {
  CNPJ,
  CPF,
}
