import axios, { Method } from 'axios';
import isUrl from 'is-url';
import joinUrl from 'proper-url-join';
import { APICallExtras } from './APICallExtras';
import moment, { Moment } from 'moment-timezone';
import { ApiException } from './interfaces';

export class APICall {
  private baseURL: string;
  private accessToken?: string;
  private httpsAgent?: string;
  private httpAgent?: string;
  private debug: boolean;
  private axiosOptions: any;

  private requestOptions: any = {};

  public multipartContent: boolean = false;

  public xEventAlias?: any;
  public xDeviceId?: any;
  public xCheckoutSession?: any;

  private removeFieldsFromResult = [
    'keyName',
    'logData',
    'tableName',
    'GetHash',
  ];

  constructor(
    baseURL: string,
    accessToken?: string,
    httpsAgent?: string,
    httpAgent?: string,
    debug: boolean = false,
    axiosOptions: any = {}
  ) {
    if (!isUrl(baseURL)) throw new Error('URL Base inválida');

    this.baseURL = baseURL;
    this.httpsAgent = httpsAgent;
    this.httpAgent = httpAgent;
    this.accessToken = accessToken;
    this.debug = debug;
    this.axiosOptions = axiosOptions;
  }

  public setAccessToken(token: string): void {
    this.accessToken = token;
  }

  public setDeviceId(id: string): void {
    this.xDeviceId = id;
  }

  public setWorkingEvent(alias: string): void {
    this.xEventAlias = alias;
  }

  public setCheckoutSession(token: string): void {
    this.xCheckoutSession = token;
  }

  public addRequestOption(key: string, value: any): APICall {
    this.requestOptions[key] = value;
    return this;
  }

  public getRequestOption(key: string): void {
    const o = this.requestOptions[key];
    return o;
  }

  public perPage(limit: number = 20): APICall {
    this.addRequestOption('limit', limit);

    return this;
  }

  public page(pageNumber: number = 1): APICall {
    this.addRequestOption('page', pageNumber);

    return this;
  }

  public include(include: any): APICall {
    if (Array.isArray(include)) {
      this.addRequestOption('include', include.join(','));
    } else {
      this.addRequestOption('include', include);
    }

    return this;
  }

  public load(include: any): APICall {
    return this.include(include);
  }

  public send(
    method: Method,
    apiVersion: string = 'v1',
    url: string,
    data: any = {},
    baseURL?: string,
    extras?: { dateFields?: Array<string> }
  ): Promise<any> {
    let callURL: any = null;

    if (baseURL !== null && baseURL !== undefined) {
      callURL = joinUrl(baseURL, apiVersion, url, { trailingSlash: false });
    } else {
      callURL = joinUrl(this.baseURL, apiVersion, url, {
        trailingSlash: false,
      });
    }

    let headers: any = {};

    headers['Authorization'] = 'Bearer ' + this.accessToken;
    headers['X-SDK'] = 'js-1.0.0';
    // headers['User-Agent'] = 'MymbaSDK/1.0';

    if (this.multipartContent) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    if (this.xEventAlias !== undefined && this.xEventAlias !== null) {
      headers['X-Event-Alias'] = this.xEventAlias;
    }

    if (this.xDeviceId !== undefined && this.xDeviceId !== null) {
      headers['X-Device-Id'] = this.xDeviceId;
    }

    if (this.xCheckoutSession !== undefined && this.xCheckoutSession !== null) {
      headers['X-Checkout-Session'] = this.xCheckoutSession;
    }

    if (method === 'POST' || method === 'PUT') {
      headers['Accept'] = 'application/json';
    } else if (Object.keys(data).length && data.constructor === Object) {
      callURL = joinUrl(callURL, { trailingSlash: false, query: data });
    }

    callURL = joinUrl(callURL, {
      trailingSlash: false,
      query: this.requestOptions,
    });

    if (this.debug) {
      const d = new Date();
      console.log(
        '[' +
          (d.getHours() + ':' + d.getMinutes()) +
          '] ' +
          method +
          ' => ' +
          callURL
      );
      console.log('> REQUEST');

      if (data !== {}) {
        console.log(data);
      }
    }

    this.requestOptions = {};
    this.multipartContent = false;

    data = this.parseRequest(data, extras);

    return (
      axios(callURL, {
        httpsAgent: this.httpsAgent,
        httpAgent: this.httpAgent,
        method,
        data: data,
        headers,
        ...this.axiosOptions,
      })
        .then(response => {
          if (this.debug) {
            console.log(
              '> RESPONSE ' + response.status + ' ' + response.statusText
            );

            try {
              console.log(response.data.data);
            } catch (ee) {
              console.log(response.data);
              console.log('FULL RESPONSE:' + response);
            }

            console.log(' ');
          }

          try {
            if (response.status !== null && response.status !== undefined) {
              if (response.status >= 200 && response.status <= 202) {
                let result: any = this.parseResponse(response);

                return {
                  ...response,
                  data: result,
                  headers: response.headers,
                };
              } else {
                const result: any = this.parseErrorResponse(response);

                return Promise.reject(result);
              }
            }
          } catch (e) {
            if (this.debug) {
              console.log('RESPONSE PARSE ERROR:', e);
            }
          }

          return response;
        })
        .then(resultBefore => {
          return this.parseResponseWithMutators(resultBefore, extras);
        })
        // .then(resultBefore => {
        //   if (resultBefore && resultBefore.headers) {
        //     if (!resultBefore.headers['x-total-count']) {
        //       resultBefore.headers['x-total-count'] = 0;
        //     } else {
        //       resultBefore.headers['x-total-count'] = parseInt(
        //         resultBefore.headers['x-total-count']
        //       );
        //     }
        //   }

        //   return resultBefore;
        // })
        .catch(e => {
          if (e.code !== undefined && e.code !== null) {
            switch (e.code) {
              case 'UNABLE_TO_VERIFY_LEAF_SIGNATURE':
                console.error(
                  'Solicitação bloqueada! Impossivel comunicar com o servidor sem uma conexão segura.'
                );

                return Promise.reject(
                  new Error(
                    'Solicitação bloqueada! Impossivel comunicar com o servidor sem uma conexão segura. (' +
                      e.code +
                      ')'
                  )
                );
              case 'ERR_CONNECTION_TIMED_OUT':
                console.error(
                  'Tempo de execução excedido (ERR_CONNECTION_TIMED_OUT).'
                );

                return Promise.reject(
                  new Error('Tempo de execução excedido (' + e.code + ').')
                );
            }
          }

          try {
            if (this.debug) {
              try {
                if (
                  e.response !== null &&
                  e.response !== undefined &&
                  e.response.status !== null &&
                  e.response.status !== undefined
                ) {
                  console.log(
                    '> RESPONSE ' +
                      e.response.status +
                      ' ' +
                      e.response.statusText
                  );
                  console.log(e.response.data);
                  console.log(' ');
                } else {
                  console.log('> RESPONSE');
                  console.log(e.response.data);
                  console.log(' ');
                }
              } catch (e) {
                console.log('DEBUG ERROR:', e);
              }
            }

            switch (e.errno) {
              case 'ENOTFOUND':
                console.error('A URL da api não esta acessível: ' + callURL);

                return Promise.reject(
                  new Error(
                    'Não foi possível estabelecer comunicação com o servidor'
                  )
                );
              case 'ERR_CONNECTION_TIMED_OUT':
                console.error(
                  'Tempo de execução excedido (ERR_CONNECTION_TIMED_OUT).'
                );

                return Promise.reject(
                  new Error('Tempo de execução excedido (' + e.code + ').')
                );
            }

            let rStatus = 400;
            if (
              e !== null &&
              e.response !== null &&
              e.response !== undefined &&
              e.response.status !== null &&
              e.response.status !== undefined
            ) {
              rStatus = e.response.status;
            }

            if (rStatus >= 401) {
              const result: any = this.parseErrorResponse(e.response);

              return Promise.reject(result);
            }

            if (rStatus == 400) {
              const result: any = this.parseValidationErrorResponse(e.response);

              return Promise.reject(result);
            }
          } catch (jsError) {
            if (this.debug) {
              console.log('GENERAL ERROR: ', jsError);
            }

            return Promise.reject({
              response: null,
              data: null,
              message: 'Erro desconhecido! Entre em contato com o Mymba!',
            });
          }

          return Promise.reject({
            response: null,
            data: null,
            message: 'Erro desconhecido! Entre em contato com o Mymba.',
          });
        })
    );
  }

  public parseResponse(response: any): any {
    let result: any = {};

    if (response.data !== null && response.data !== undefined) {
      if (response.data.data !== null && response.data.data !== undefined) {
        if (
          response.data.data.data !== null &&
          response.data.data.data !== undefined
        ) {
          result = response.data.data.data;
        } else {
          result = response.data.data;
        }
      } else {
        result = response.data;
      }

      if (result === undefined || result === null) {
        result = response.data.data;
      }

      if (
        response.data.message !== undefined &&
        response.data.message !== null
      ) {
        result.__server_message = response.data.message;
      }
    }

    response.response = response;

    if (this.removeFieldsFromResult) {
      for (let col in this.removeFieldsFromResult) {
        delete result[this.removeFieldsFromResult[col]];
      }
    }

    return result;
  }

  public parseResponseWithMutators(resultBefore: any, extras?: APICallExtras) {
    // Mutate with extras

    let result: any = resultBefore.data;

    if (extras) {
      if (extras.dateFields) {
        for (let col in extras.dateFields) {
          const extra = extras.dateFields[col];

          if (Array.isArray(result)) {
            for (let i = 0; i < result.length; i++) {
              result[i] = {
                ...result[i],
                [extra]: moment(result[i][extra]),
              };
            }
          } else {
            result[extra] = moment(result[extra]);
          }

          // TODO - Rodar recursivo
        }
      }
    }

    resultBefore.data = result;

    return resultBefore;
  }

  public parseRequest(data: any, extras?: APICallExtras): any {
    if (extras) {
      if (extras.dateFields) {
        for (let col in extras.dateFields) {
          const extra = extras.dateFields[col];

          const formatDate = (date: Moment | string) => {
            if (!date) {
              return date;
            }

            if (typeof date === 'string') {
              return date;
            } else {
              return date.format('YYYY-MM-DDTHH:mm:ssZ');
            }
          };

          if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
              data[i] = {
                ...data[i],
                [extra]: formatDate(data[i][extra]),
              };
            }
          } else {
            data[extra] = formatDate(data[extra]);
          }
        }
      }
    }

    return data;
  }

  public parseErrorResponse(errorResponse: any): ApiException {
    let result: ApiException = {
      data: undefined,
      response: undefined,
      message: undefined,
      status: {
        code: 501,
        text: 'Erro desconhecido.',
      },
    };

    if (errorResponse === null || errorResponse === undefined) {
      return result;
    }

    if (errorResponse.status === 500) {
      result.message = errorResponse.data.Message;
    } else {
      result.message = errorResponse.data.message;
    }

    result.response = errorResponse;
    result.data = errorResponse.data;
    result.status = {
      code: errorResponse.status,
      text: errorResponse.statusText,
    };

    return result;
  }

  public parseValidationErrorResponse(response: any): any {
    let validationErrors: any = {};

    const errors = response.data.errors;
    const errorKeys = Object.keys(errors);

    for (let i = 0; i < errorKeys?.length; i++) {
      const errKey = errorKeys[i];
      const cleanErrKey = errKey.replace('model.', '');

      const exErrors = errors[errKey].Errors;

      let messages = [];
      for (let j = 0; j < exErrors?.length; j++) {
        const errorException = exErrors[j];
        messages.push(errorException.ErrorMessage);
      }

      validationErrors[cleanErrKey] = messages;
    }

    let result: ApiException = {
      data: {
        ValidationErrors: validationErrors,
      },
      response: response,
      message: response.data.message,
      status: {
        code: response.status,
        text: response.statusText,
      },
    };

    return result;
  }
}
