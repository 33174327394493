import { Empresa } from './Empresa';

export type Usuario = {
  idUsuario?: number;

  /** Código da empresa */
  idEmpresa?: number;

  /** Nome completo */
  nmUsuario: string;

  /** E-mail para login */
  dsEmail: string;

  /** Nome de usuario para login */
  dsLogin?: string;

  /** Ativo/Inativo */
  flSituacao: string;

  /** Descrição de situação do usuario */
  dsSituacao: string;

  /** Empresa do usuário */
  Empresa?: Empresa;
};

export enum UsuarioSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
