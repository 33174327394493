import { Movimento } from './Movimento';
import { TipoMovimento } from './TipoMovimento';

export type DetalheMovimento = {
  id: number;
  vlDetalhe: number;
  flSituacao: DetalheMovimentoSituacao;
  Tipo: TipoMovimento;
  Movimento?: Movimento;
};

export enum DetalheMovimentoSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
