import { Mymba } from '../Mymba';
import { APIModelDefaults, APIModelDefaultsProps } from './APIModelDefaults';
import { APICall } from './APICall';
import { ApiResponse } from './interfaces';
import { ToggleState } from './ToggleState';

export abstract class Controller<T> {
  protected defaults: APIModelDefaults;

  protected dateFields: Array<string> = [];

  constructor(
    protected baseRoute: string,
    protected apiVersion: string = 'v1',
    defaults: APIModelDefaultsProps = {}
  ) {
    this.defaults = {
      ...{
        softDeletePropName: 'flSituacao',
        pkKey: 'id',
      },
      ...defaults,
    };
  }

  protected getDefaults() {
    return this.defaults;
  }

  protected route(name: any = ''): string {
    let route = this.baseRoute;
    name = String(name);

    if (name !== null && name !== undefined && name.trim() !== '') {
      route += '/' + name;
    }

    return route;
  }

  public getSDK(): Mymba {
    return Mymba.instance;
  }

  public Api(): APICall {
    return this.getSDK().getApi();
  }

  public addRequestOption(key: string, value: any): Controller<T> {
    this.getSDK()
      .getApi()
      .addRequestOption(key, value);
    return this;
  }

  public addParam(key: string, value: any): Controller<T> {
    this.getSDK()
      .getApi()
      .addRequestOption(key, value);
    return this;
  }

  public searchFor(value: any): Controller<T> {
    this.getSDK()
      .getApi()
      .addRequestOption('q', value);
    return this;
  }

  public getRequestOption(key: string): void {
    return this.getSDK()
      .getApi()
      .getRequestOption(key);
  }

  public perPage(limit: number = 20): Controller<T> {
    this.getSDK()
      .getApi()
      .perPage(limit);
    return this;
  }

  public page(pageNumber: number = 1): Controller<T> {
    this.getSDK()
      .getApi()
      .page(pageNumber);
    return this;
  }

  public include(include: any): Controller<T> {
    this.getSDK()
      .getApi()
      .include(include);
    return this;
  }

  public load(include: any): Controller<T> {
    this.getSDK()
      .getApi()
      .load(include);
    return this;
  }

  //// \\\\

  public List(
    page: number = 1,
    limit: number = 20
  ): Promise<ApiResponse<Array<T>>> {
    return this.Api().send(
      'GET',
      this.apiVersion,
      this.route('/'),
      {
        page,
        limit,
      },
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public FindOne(id: number | string): Promise<ApiResponse<T>> {
    return this.Api().send(
      'GET',
      this.apiVersion,
      this.route(`/${id}`),
      {},
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public Update(
    id: number | string,
    data: Omit<T, 'id'>
  ): Promise<ApiResponse<T>> {
    return this.Api().send(
      'PUT',
      this.apiVersion,
      this.route(`/${id}`),
      data,
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public Create(data: Omit<T, 'id'> | T): Promise<ApiResponse<T>> {
    return this.Api().send(
      'POST',
      this.apiVersion,
      this.route(`/`),
      data,
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public Delete(id: number | string): Promise<void> {
    return this.Api().send(
      'DELETE',
      this.apiVersion,
      this.route(`/${id}`),
      {},
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public Disable(id: number | string): Promise<ApiResponse<T>> {
    return this.Api().send(
      'PUT',
      this.apiVersion,
      this.route(`/${id}/toggle`),
      {
        [this.defaults.softDeletePropName]: false,
      },
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public Enable(id: number | string): Promise<ApiResponse<T>> {
    return this.Api().send(
      'PUT',
      this.apiVersion,
      this.route(`/${id}/toggle`),
      {
        [this.defaults.softDeletePropName]: true,
      },
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public DisableMany(
    ids: number[] | string[]
  ): Promise<ApiResponse<Array<ToggleState>>> {
    return this.Api().send(
      'PUT',
      this.apiVersion,
      this.route(`/toggleMany`),
      {
        ids: ids,
        flSituacao: false,
      },
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }

  public EnableMany(
    ids: number[] | string[]
  ): Promise<ApiResponse<Array<ToggleState>>> {
    return this.Api().send(
      'PUT',
      this.apiVersion,
      this.route(`/toggleMany`),
      {
        ids: ids,
        flSituacao: true,
      },
      undefined,
      {
        dateFields: this.dateFields,
      }
    );
  }
}
