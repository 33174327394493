import { Moment } from 'moment';

export type ContaReceber = {
  id: number;
  dtVencimento: Moment;
  vlContaReceber: number;
  flSituacao: ContaReceberSituacao;
  flTipoPgto: ContaReceberTipoPgto;
  byArquivo: string;
};

export enum ContaReceberSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}

export enum ContaReceberTipoPgto {
  ANY = 'X',
}
