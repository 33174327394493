import { Moment } from 'moment-timezone';

export type Certificado = {
  id: number;
  dsSenha?: string;
  nrCertificado: string;
  dtValidade: Moment;
  flSituacao: CertificadoSituacao;
};

export enum CertificadoSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
