import { Controller } from '../Api/Controller';
import { CFOP } from '../Models';

export class CFOPController extends Controller<CFOP> {
  constructor() {
    super('cfops', 'v1', {
      pkKey: 'nrCFOP',
    });
  }
}
