export type TipoPagamento = {
  id: number;
  nmTipo: string;
  dsTipo?: string;
  flTipo: string;
};

export enum TipoPagamentoSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
