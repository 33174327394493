import { ApiResponse } from '../Api';
import { Controller } from '../Api/Controller';
import { Usuario } from '../Models';

export class UsuariosController extends Controller<Usuario> {
  constructor() {
    super('usuarios', 'v1');
  }

  public Me(): Promise<ApiResponse<Usuario>> {
    return this.Api().send('GET', 'v1', this.route('me'));
  }
}
