export enum TpEmis {
  teNormal = 1,
  teFSIA = 2,
  teSCAN = 3,
  teDPEC = 4,
  teFSDA = 5,
  teSVCAN = 6,
  teSVCRS = 7,
  teOffLine = 9,
}
