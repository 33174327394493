import { EmitCRT } from './Enum/EmitCRT';
import { TEnderEmi } from './TEnderEmi';

export type Emit = {
  Documento: string | 'CNPJ' | 'CPF';
  TipoDocumento: EmitTipoDocumento;
  xNome: string;
  xFant: string;
  enderEmit: TEnderEmi;
  IE: string;
  IEST: string;
  IM: string;
  CNAE: string;
  CRT: EmitCRT;
};

export enum EmitTipoDocumento {}
