import { Controller } from '../Api/Controller';
import { Estado } from '../Models';

export class EstadosController extends Controller<Estado> {
  constructor() {
    super('estados', 'v1', {
      pkKey: 'idUf',
    });
  }
}
