export type CFOP = {
  id?: number;
  nrCFOP: string;
  flSituacao: CFOPSituacao;
  dsCFOP: string;
  dsAplicacao: string;
};

export enum CFOPSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
