import { Moment } from 'moment';
import { TipoMovimento } from './TipoMovimento';

export type Movimento = {
  id: number;
  dsMovimento: string;
  vlMovimento: number;
  flSituacao: MovimentoSituacao;
  dtMovimento: Moment;
  dtInclusao: Moment;
  Tipo: TipoMovimento;
};

export enum MovimentoSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}
