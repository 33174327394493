export type Produto = {
  id: number;
  idEmpresa?: number;

  flTipo?: ProdutoTipo;
  flSituacao: ProdutoSituacao;
  flGrade?: ProdutoGrade;

  vlVenda: number;
  vlCusto: number;

  nmProduto: string;

  dsProduto?: any;

  cdMpn?: any;

  nrGtin?: any;
  nrNcm?: any;
  nrPeso: number;
  nrAltura: number;
  nrLargura: number;
  nrProfundidade: number;

  atributos?: Array<ProdutoAtributoRelation>;
};

export type ProdutoAtributoRelation = {
  idTipo: number; // TipoAtributo
  dsValor: string;
  nmTipo?: string;
  flTipo?: string;
};

export type ProdutoImagem = {
  id: number;
  flSituacao?: ProdutoImagemSituacao;
  nrOrdem: number;
  File: string;
};

export enum ProdutoTipo {
  ANY = 'X',
}

export enum ProdutoGrade {
  ANY = 'X',
}

export enum ProdutoSituacao {
  ATIVO = 'A',
  INATIVO = 'I',
}

export enum ProdutoImagemSituacao {
  NORMAL = 'N',
  REMOVIDO = 'R',
}
